.side-image-section-right {
  width: 50%;
}

.side-image-section-right section {
  margin-left: 40px;
  padding: 56px 0;
  max-height: 960px;
  overflow: hidden;
}

.overflow-image {
  width: 1400px;
  max-height: 100%;
  border-radius: 4px 0 0 0;
}

/* medium */
@media screen and (max-width: 1299px) and (min-width: 736px) {
}

/* Small size */
@media screen and (max-width: 736px) {
  .side-image-section-right {
    width: 100%;
    margin: 0;
    padding: 56px 16px 56px 16px;
  }

  .side-image-section-right section {
    margin-left: 0;
    padding: 0;
    border-radius: 4px;
    max-height: 480px;
  }

  .overflow-image {
    border-radius: 4px;
  }
}
