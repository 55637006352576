@import "../App/colors";
@import "../App/elevation";

:root {
  background-color: $light-blue;
}

.appFull {
  background-color: $light-blue;
}

.round-logo-image {
  height: 64px;
  width: 64px;
  background-color: $white;
  border-radius: 50%;
}

.logo {
  margin: 0;
  padding: 32px 0px 32px 24px;
  height: 120px;
  width: 100%;
  position: fixed;
  background-color: $light-blue;
  top: 0;
}

.logo-elevation {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition: box-shadow 250ms ease-out;
}

.round-logo-image {
  position: absolute;
  left: calc(50% - 32px);
}

.cards {
  margin-top: 120px;
}

.card {
  box-shadow: $elevation-2;
  border-radius: 4px;
  margin: 0 128px 40px 128px;
  background-color: $chalk-white;
}

@media screen and (max-width: 1039px) and (min-width: 912px) {
  .card {
    margin: 0 72px 40px 72px;
  }
}

@media screen and (max-width: 912px) {
  .card {
    margin: 0 8px 40px 8px;
  }
}

.mobile-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media screen and (max-width: 736px) {
  .mobile-card {
    display: flex;
    flex-direction: column;
  }
}

.desktop-card {
  display: flex;
  flex-direction: column;
}

.button {
  height: 120px;
  position: fixed;
  top: 0;
  width: 72px;
  cursor: pointer;
}

.button > .circle-container > .circle > img {
  width: 40px;
  height: 40px;
  padding: 12px;
  margin-top: 0;
  margin-left: 16px;
  margin-right: 16px;
}

.left {
  left: 0;
}

.right {
  right: 0;
}

.right img {
  transform: rotate(180deg);
}

.circle-container {
  position: relative;
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin-top: 40px;
}

.circle::after {
  position: absolute;
  top: 0;
  left: 16px;
  border-radius: 40px;
  pointer-events: none;
  content: "";
  border: solid 4px $davys-grey;
  opacity: 0;
  width: 40px;
  height: 40px;
  transition: opacity 150ms linear;
}

@media (pointer: fine) {
  .circle:hover::after {
    opacity: 1;
    transition: background-color 150ms linear;
  }
}
