$font-family: "Gotham Narrow A", "Gotham Narrow B", "Helvetica Neue", Helvetica,
  Arial, sans-serif;
$line-height: 1.5em;

html {
  font: 16px/1.5em $font-family;
}

h1 {
  color: transparentize($charleston-green, 0.13);
  font-size: 2em;
  font-weight: 500;
  line-height: $line-height;
  margin: 0;

  .highlight {
    color: $nycc-red;
  }
}

h2 {
  color: $charleston-green;
  font-size: 1.75em;
  font-weight: 700;
  line-height: $line-height;
  margin: 0;
}

h2 a {
  color: $charleston-green;
}

h2 a:visited {
  color: $charleston-green;
}

h2 a:active {
  color: $charleston-green;
}

h3 {
  color: $charleston-green;
  font-size: 1.25em;
  font-weight: 300;
  line-height: $line-height;
  margin: 0;
}

h3 a {
  color: $charleston-green;
}

h3 a:visited {
  color: $charleston-green;
}

h3 a:active {
  color: $charleston-green;
}

h4 {
  color: $charleston-green;
  font-size: 1.125em;
  font-weight: 500;
  line-height: $line-height;
  margin: 0;
}

h4 a:active {
  color: $charleston-green;
}

h4 a:visited {
  color: $charleston-green;
}

h5 {
  color: $charleston-green;
  font-size: 1.125em;
  font-weight: 100;
  line-height: $line-height;
  margin: 0;
}

h5-grey {
  color: $davys-grey;
  font-size: 1.125em;
  font-weight: 100;
  line-height: $line-height;
  margin: 0;
}

small {
  font-size: 1em;
  font-weight: 400;
}

.label {
  color: $charleston-green;
  font-size: 0.75em;
  font-weight: 500;
  line-height: $line-height;
  margin: 0;
}

.label-light {
  color: $davys-grey;
  font-size: 0.75em;
  font-weight: 300;
  line-height: $line-height;
  margin: 0;
}

.fine-print {
  color: $charleston-green;
  font-size: 0.75em;
  font-weight: 300;
  line-height: $line-height;
  margin: 0;
}

p {
  color: $charleston-green;
  font-size: 1.125em;
  font-weight: 300;
  line-height: $line-height;
}

@mixin largeCTA {
  font-size: 1em;
  font-weight: 700;
  font-family: $font-family;
  text-transform: uppercase;
}

@mixin errorLabel {
  color: $nycc-red;
  font-weight: 700;
}

@mixin smallButton {
  font-size: 0.75em;
  font-family: $font-family;
  font-weight: 500;
  line-height: $line-height;
}

@mixin standardLink {
  color: $charleston-green;
  text-decoration: none;
  font-weight: 400;
}

@mixin standardLinkHover {
  text-decoration: underline;
}

@mixin mediumButton {
  text-transform: uppercase;
  text-align: right;
  font-size: 1em;
  font-weight: 500;
}
