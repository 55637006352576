@import "../App/colors";
@import "../App/typography";

.HomeContent {
  a,
  a:active,
  a:visited {
    @include standardLink;
  }

  a:hover {
    @include standardLinkHover;
  }

  p {
    margin-top: 16px;
  }
}

/* Large size */
@media screen and (min-width: 960px) {
  .HomeContent {
    margin: 152px 0 152px 50px;
  }
}

/* Medium size */
@media screen and (max-width: 959px) and (min-width: 701px) {
  .HomeContent {
    margin: 152px 0 152px 50px;
    margin-top: 152px;
    margin-right: 0px;
    margin-bottom: 152px;
    margin-left: 8%;
  }
}

/* Small size */
@media screen and (max-width: 700px) {
  .HomeContent {
    margin: 24px 16px 152px 16px;
  }
}

.home-intro {
  margin-top: 16px;
}

.home-call-button {
  margin-top: 40px;

  small {
    margin-top: 8px;
    display: block;
  }
}
