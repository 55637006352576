@import "../App/colors";
@import "../App/typography";

body {
  background-color: $chalk-white;
}

/* Largest screen size */
@media screen and (min-width: 960px) {
  .pageSplit {
    max-width: 960px;
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .appSplit {
    background: linear-gradient(
      to right,
      $davys-grey 0%,
      $charleston-green 50%,
      $chalk-white 50%,
      $chalk-white 100%
    );
  }

  .panel-left {
    height: 100vh;
    width: 50%;
    float: left;
  }

  .panel-right {
    background-color: $chalk-white;
    float: right;
    width: 50%;
  }

  .nav-menu {
    display: none !important;
  }

  .bm-burger-button {
    display: none !important;
  }
}

/* Medium size */
@media screen and (max-width: 959px) and (min-width: 701px) {
  .pageSplit {
    width: auto;
  }

  .appSplit {
    background: linear-gradient(
      to right,
      $davys-grey 0%,
      $charleston-green 50%,
      $chalk-white 50%,
      $chalk-white 100%
    );
  }

  .panel-left {
    height: 100vh;
    width: 50%;
    float: left;
  }

  .panel-right {
    background-color: $chalk-white;
    float: right;
    width: 50%;
  }

  .nav-menu {
    display: none !important;
  }

  .bm-burger-button {
    display: none !important;
  }
}

/* Small size */
@media screen and (max-width: 700px) {
  .pageSplit {
    width: auto;
    margin: 0;
  }

  .panel-left {
    height: 310px;
    width: 100%;
    background: radial-gradient($davys-grey, $charleston-green);
  }

  .panel-right {
    background-color: $chalk-white;
    width: 100%;
  }

  /* Position and sizing of burger button */
  .bm-burger-button {
    position: absolute;
    width: 28px;
    height: 24px;
    top: 16px;
    right: 16px;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #fff;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    right: 16px !important;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #fff;
    height: 29px !important;
    width: 4px !important;
  }

  /* General sidebar styles */
  .bm-menu {
    background: radial-gradient($davys-grey, $charleston-green);
    padding: 2.5em 1.5em 0;
    font-size: 1.125em;
  }

  .bm-item-list {
    margin-top: 80px;
  }

  .bm-item-list li {
    padding-bottom: 32px;
    text-align: center;
  }

  .bm-item-list a {
    text-decoration: none;
    color: $chalk-white;
  }

  .bm-item-list a:visited {
    color: $chalk-white;
  }

  .bm-item-list a:hover {
    cursor: pointer;
    color: $dark-turquoise;
  }

  .bm-item-list a.active-nav {
    color: $dark-turquoise;
  }

  .menu-nav {
    @include mediumButton;
    text-decoration: none;
    text-align: center;
    color: $chalk-white;
    line-height: $line-height;
    margin: 0;
  }
}

/* parent will collapse to zero height since there are only floated children */
.pageSplit:after {
  content: "";
  display: table;
  clear: both;
}
