@import "../App/colors";

.c-product-hunt {
  margin-top: 40px;
  font-size: 1em;

  span {
    display: block;
    height: 48px;
    line-height: 48px;
  }

  a {
    text-decoration: none;
    color: $charleston-green;
  }

  a:hover,
  a:active,
  a:visited {
    text-decoration: underline;
    color: $charleston-green;
  }

  &__image-container img {
    height: 48px;
    width: 48px;
    margin-right: 16px;
    float: left;
  }
}
