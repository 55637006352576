@import "../App/colors";

.c-section-left {
  width: 50%;
  min-width: 280px;
  max-width: 560px;
  margin-right: 40px;

  ul {
    list-style-type: disc;
    list-style-position: outside;
    list-style-image: none;
    margin-top: 24px;

    li {
      margin-top: 16px;
    }
  }

  &__year {
    padding-top: 16px;
  }
}

/* x-large */
@media screen and (min-width: 1300px) {
  .c-section-left {
    padding: 152px 0 56px 12%;
  }
}

/* medium */
@media screen and (max-width: 1299px) and (min-width: 736px) {
  .c-section-left {
    padding: 80px 0 56px 12%;
    margin-right: 0;
  }
}

/* Small size */
@media screen and (max-width: 736px) {
  .c-section-left {
    order: 1;
    margin-right: 0;
    padding: 0 16px 40px 16px;
    width: 100%;

    ul {
      list-style-position: inside;
    }
  }
}

.c-citation-container {
  border: solid 1px rgba(90, 90, 99, 0.24);
  border-radius: 4px;
  margin-top: 40px;
  padding: 16px;
}

.c-citation {
  font-size: 1em;

  &__image {
    height: 48px;
    width: 48px;
    margin-right: 16px;
    float: left;
  }

  &__quote {
    padding-top: 8px;
    display: block;
  }

  &__company {
    font-size: 0.75em;
    display: block;
    height: 24px;
    line-height: 16px;
    padding-bottom: 8px;
  }

  &__person {
    display: inline-block;
    height: 24px;
    line-height: 16px;
    padding-top: 8px;
  }

  a {
    text-decoration: none;
    color: $charleston-green;
  }

  a:hover,
  a:active,
  a:visited {
    text-decoration: underline;
    color: $charleston-green;
  }
}

.c-project-description {
  padding-top: 16px;
  color: $charleston-green;
  font-size: 1.125em;
  font-weight: 300;
  line-height: 1.5em;
}
