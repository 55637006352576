$nycc-red: hsla(7, 100%, 56%, 1);
$dark-turquoise: hsla(181, 69.49%, 48.38%, 1);
$charleston-green: hsla(240, 10.5%, 15.39%, 1);
$davys-grey: hsla(240, 4.71%, 37.24%, 1);
$white: hsla(0, 0%, 100%, 1);
$chalk-white: rgba(252, 252, 255, 1);
$gargoyle-gas: hsla(52, 100%, 65%, 1);
$glossy-grape: hsla(268, 17.06%, 66.24%, 1);
$black-coral: hsla(226, 12.36%, 40.05%, 1);
$shadow: hsla(0, 0%, 0%, 0.5);
$light-blue: rgba(238, 238, 238, 1);
