.section-right {
  width: 50%;
}

.section-right {
  section {
    max-width: 560px;
    margin-left: 40px;
    padding: 56px 12% 56px 0;

    video {
      margin-top: 96px;
      margin-bottom: 152px;
    }
  }
}

.mobileImg {
  max-width: 100%;
  min-width: 280px;
}

/* show and hide prototype */
@media screen and (min-width: 1200px) {
  .c-prototype {
    display: block;
  }

  .c-prototype-placeholder {
    display: none;
  }
}

@media screen and (max-width: 1199px) {
  .c-prototype {
    display: none;
  }

  .c-prototype-placeholder {
    display: block;
  }
}

/* medium */
@media screen and (max-width: 1299px) and (min-width: 736px) {
  .section-right {
    section {
      margin-left: 40px;
      padding: 56px 12% 56px 0;

      video {
        margin-top: 24px;
        margin-bottom: 152px;
      }
    }
  }
}

/* Small size */
@media screen and (max-width: 736px) {
  .section-right {
    width: 100%;

    section {
      margin: 0;
      padding: 56px 16px 56px 16px;

      video {
        margin: 0;
      }
    }
  }
}
