@import "../App/colors";
@import "../App/typography";

/* Remove scroll on the body when react-modal is open */
.ReactModal__Body--open {
  overflow: hidden;
}

.assessment-button {
  display: block;
  height: 40px;
  background-color: $chalk-white;
  border: 2px solid $charleston-green;
  border-radius: 4px;
  color: $charleston-green;
  transition: background-color 15ms linear;
  @include largeCTA;
}

.assessment-button:hover {
  cursor: pointer;
  transition: background-color 15ms linear;
  background-color: transparentize($glossy-grape, 0.5);
}

.assessment-button:focus {
  outline: none;
}

.wrapper {
  width: 100%;
}

.ReactModal__Body--open {
  transition: all 500ms ease;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $dark-turquoise;
}

.modal-logo {
  width: 60px;
  height: 60px;
  margin-top: 9px;
  float: left;
}

@media screen and (min-width: 426px) {
  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    outline: none;
    min-width: 288px;
    max-width: 380px;
    width: 100%;
  }

  .modal-headline {
    color: $charleston-green;
    max-width: 304px;
    width: 100%;
    margin: 0px 0px 40px 16px;
    padding-top: 0px;
    float: right;
  }
}

@media screen and (max-width: 425px) {
  .modal {
    border: 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    outline: none;
    min-width: 288px;
    max-width: 380px;
    width: 100%;
    padding: 72px 16px 0px 16px;
  }

  .modal-headline {
    color: $charleston-green;
    max-width: 267px;
    width: 100%;
    margin: 0px 0px 40px 16px;
    padding-top: 10px;
    float: right;
  }
}

@media screen and (max-width: 374px) {
  .modal {
    border: 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    outline: none;
    min-width: 288px;
    max-width: 380px;
    width: 100%;
    padding: 72px 16px 0px 16px;
  }

  .modal-headline {
    color: $charleston-green;
    max-width: 248px;
    width: 100%;
    margin: 0px 0px 40px 16px;
    padding-top: 10px;
    float: right;
  }
}

@media screen and (max-width: 355px) {
  .modal {
    border: 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    outline: none;
    min-width: 288px;
    max-width: 380px;
    width: 100%;
    padding: 72px 16px 0px 16px;
  }

  .modal-headline {
    color: $charleston-green;
    max-width: 212px;
    width: 100%;
    margin: 0px 0px 40px 16px;
    float: right;
  }
}

.modal-body {
  clear: both;
}

.email-label {
  color: $charleston-green;
  margin-top: 40px;
}

.email-label-hidden {
  display: none;
}

.invalid-label {
  @include errorLabel;
  margin-top: 40px;
}

.invalid-label-hidden {
  display: none;
}

.email-input {
  max-width: 380px;
  width: 100%;
  height: 56px;
  border: solid 2px $charleston-green;
  border-radius: 4px;
  padding-left: 16px;
  outline: 0;
  font-size: 1em;
}

.modal-submit {
  display: block;
  margin-top: 8px;
  height: 40px;
  background-color: $dark-turquoise;
  border: 2px solid $charleston-green;
  border-radius: 4px;
  color: $charleston-green;
  transition: background-color 15ms linear;
  @include largeCTA;
}

.modal-submit:hover {
  cursor: pointer;
  transition: background-color 15ms linear;
  background-color: transparentize($glossy-grape, 0.5);
}

.modal-submit:focus {
  outline: none;
}

.modal-close {
  background-color: $dark-turquoise;
  border: 0;
  margin-top: 24px;
  color: $charleston-green;
  @include smallButton;
}

.modal-close:hover {
  cursor: pointer;
}

.carrot {
  margin-top: 10px;
  margin-right: 8px;
}
