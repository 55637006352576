@import "../App/colors";

.ProjectList {
  @media screen and (min-width: 960px) {
    margin: 152px 0 152px 50px;
  }

  @media screen and (max-width: 959px) and (min-width: 816px) {
    margin: 152px 0 152px 50px;
    margin-top: 152px;
    margin-right: 0px;
    margin-bottom: 152px;
    margin-left: 8%;
  }

  @media screen and (max-width: 815px) {
    margin: 24px 8px 152px 8px;
  }

  p {
    margin-top: 16px;
  }

  a,
  a:hover,
  a:visited,
  a:active {
    color: $charleston-green;
    text-decoration: none;
  }
}

.project-card {
  width: 344px;
  margin: 16px 16px 16px 0;
  border-radius: 4px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition: box-shadow linear 150ms;

  &:hover {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.4),
      0 2px 2px 0 rgba(0, 0, 0, 0.28), 0 1px 5px 0 rgba(0, 0, 0, 0.24);
    transition: box-shadow linear 150ms;
  }
}
