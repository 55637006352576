@import "colors";
@import "typography";

/* page styles */

html {
  box-sizing: border-box;
}

*,
*:after,
*:before {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
}

#main-app {
  min-width: 360px;
  background-color: $white;
}
