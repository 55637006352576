@import "../App/colors";
@import "../App/typography";

/* Largest screen size */
@media screen and (min-width: 960px) {
  .NavPanel {
    margin: 96px 0 0 30px;
    width: 400px;
    position: fixed;
  }

  .logo-image {
    width: 400px;
    height: 362px;
    border: 0;
    padding: 0;
  }
}

/* Medium size */
@media screen and (max-width: 959px) and (min-width: 701px) {
  .NavPanel {
    margin: 96px 0 0 30px;
    padding-right: 8%;
    width: 50%;
    position: fixed;
  }

  .logo-image {
    max-width: 100%;
  }
}

/* Small size */
@media screen and (max-width: 700px) {
  .NavPanel {
    width: 100%;
    padding-top: 60px;
  }

  .logo-image {
    width: 210px;
    height: 190px;
    margin: 0px auto;
    display: block;
  }

  .NavList {
    display: none;
  }
}

.NavList {
  @include mediumButton;
  color: $chalk-white;
}

.NavList li {
  margin-bottom: 16px;
}

.NavPanel a {
  text-decoration: none;
  color: $chalk-white;
}

.NavPanel a:visited {
  color: $chalk-white;
}

.NavPanel a:hover {
  cursor: pointer;
  color: $dark-turquoise;
}

.NavPanel a.active-nav {
  color: $dark-turquoise;
}
